<template>
  <CRow>
    <CCol sm="6" lg="6">
      <CCard color="default" class="text-center" body-wrapper>
        <CRow>
          <CCol sm="6" lg="8" class="text-left pr-0">
            <blockquote class="card-blockquote">
              <p class="text-muted">Total Consumers</p>
              <h4>{{ total_customers }}</h4>
            </blockquote>
          </CCol>

          <CCol sm="6" lg="4" class="pt-3 text-right">
            <CIcon class="mb-2" name="cil-people" height="30" />
          </CCol>
        </CRow>
      </CCard>
    </CCol>

    <CCol sm="6" lg="6">
      <CCard color="default" class="text-center" body-wrapper>
        <CRow>
          <CCol sm="6" lg="8" class="text-left pr-0">
            <blockquote class="card-blockquote">
              <p class="text-muted">Total Meters</p>
              <h4>{{ total_meters }}</h4>
            </blockquote>
          </CCol>

          <CCol sm="6" lg="4" class="pt-3 text-right">
            <CIcon class="mb-2" name="cil-speedometer" height="30" />
          </CCol>
        </CRow>
      </CCard>
    </CCol>
    <CCol class="d-none" sm="6" lg="4">
      <CCard color="default" class="text-center" body-wrapper>
        <CRow>
          <CCol sm="6" lg="8" class="text-left pr-0">
            <blockquote class="card-blockquote">
              <p class="text-muted">Total Revenue</p>
              <h4>{{ total_revenue.toFixed(2) }}</h4>
            </blockquote>
          </CCol>

          <CCol sm="6" lg="4" class="pt-3 text-right">
            <CIcon class="mb-2" name="cil-dollar" height="30" />
          </CCol>
        </CRow>
      </CCard>
    </CCol>
    <CCol sm="6" lg="6">
      <CCard color="default" class="text-center" body-wrapper>
        <CRow>
          <CCol sm="6" lg="8" class="text-left pr-0">
            <blockquote class="card-blockquote">
              <p class="text-muted">Total Connected Meters</p>
              <h4>{{ total_connected_meter }}</h4>
            </blockquote>
          </CCol>

          <CCol sm="6" lg="4" class="pt-3 text-right">
            <CIcon class="mb-2" name="cil-speedometer" height="30" />
          </CCol>
        </CRow>
      </CCard>
    </CCol>
    <CCol sm="6" lg="6">
      <CCard color="default" class="text-center" body-wrapper>
        <CRow>
          <CCol sm="6" lg="8" class="text-left pr-0">
            <blockquote class="card-blockquote">
              <p class="text-muted">Total Disconnected Meters</p>
              <h4>{{ total_disconnected_meter }}</h4>
            </blockquote>
          </CCol>

          <CCol sm="6" lg="4" class="pt-3 text-right">
            <CIcon class="mb-2" name="cil-speedometer" height="30" />
          </CCol>
        </CRow>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
// import { CChartLineSimple, CChartBarSimple } from "../charts/index.js";

export default {
  name: "WidgetsDropdown",
  // components: { CChartLineSimple, CChartBarSimple },
  computed: {
    ...mapGetters("Dashboard", [
      "total_meters",
      "total_consumptions",
      "total_customers",
      "total_vehicle_requests",
      "total_revenue",
      "total_connected_meter",
      "total_disconnected_meter",
    ]),
  },
  mounted() {
    this.$store.dispatch("Dashboard/dashboardCounts");
  },
};
</script>

<style scoped>
.card {
  border: none;
}
</style>

<style lang="scss" scoped>
.card-body {
  .row {
    div {
      blockquote {
        h4 {
          font-size: 22px;
          font-weight: 700;
        }
        p {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}
</style>


